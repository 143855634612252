import { Card, Space, Tabs, theme } from "antd";
import { useParams } from "react-router";
import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import Logo from "../components/Logo";

const UserPage = () => {
  const { token } = theme.useToken();
  const { referrerId } = useParams<{ referrerId: string }>();
  const items = [
    { key: "login", label: "登录", children: <LoginForm /> },
    { key: "register", label: "注册", children: <RegisterForm /> },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <video
        src="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
        autoPlay
        loop
        muted
        controls={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <Card
        title={
          <Space style={{ margin: "10px" }}>
            <Logo />
            <div
              style={{
                fontSize: "2rem",
                margin: 0,
                color: token.colorPrimary,
                fontWeight: "bold",
              }}>
              Fusion Code
            </div>
          </Space>
        }
        style={{
          backgroundColor: token.colorBgBase,
          backdropFilter: "blur(4px)",
        }}>
        <Tabs
          centered
          items={items}
          defaultActiveKey={referrerId ? "register" : "login"}
        />
      </Card>
    </div>
  );
};

export default UserPage;
