import { PageData, User } from "../interface/System";
import { request, responseHandler } from "../utils/RequestUtils";

const requestMapping = "/user";

export const getUserList = async (
  page?: number,
  size?: number
): Promise<PageData<User>> => {
  return request
    .get(requestMapping, { params: { page, size } })
    .then(responseHandler);
};

export const getUser = async (id: number): Promise<User> => {
  return request.get(requestMapping + "/" + id).then(responseHandler);
};

export const saveUser = async (body: User) => {
  return request.post(requestMapping, body).then(responseHandler);
};
export const getCurrentUser = async (): Promise<User> => {
  return request.get(requestMapping + "/getUser").then(responseHandler);
};
