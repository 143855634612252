import { ConfigProvider, theme } from "antd";
import { useMemo } from "react";
import { Outlet } from "react-router";
import { useCompress, useDark } from "../store/store";

export const lightTheme = {
  token: {
    colorPrimary: "#F2BD27",
    colorInfo: "#ff9900",
    colorWarning: "#ED4192",
    colorSuccess: "#00B96B",
    colorError: "#ff4d4f",
    wireframe: true,
    colorLink: "#9E339F",
    colorBgBase: "#ffffff",
    borderRadius: 10,
    fontSize: 18,
  },
  components: {
    Button: {
      algorithm: false,
    },
    Card: {
      algorithm: false,
    },
    Tabs: {
      algorithm: false,
    },
  },
};

export const darkTheme = {
  token: {
    colorPrimary: "#378f0c",
    colorInfo: "#378f0c",
    colorWarning: "#fa541c",
    colorSuccess: "#a0d911",
    colorError: "#f5222d",
    wireframe: true,
    colorLink: "#F4801A",
    colorBgBase: "#000000df",
    borderRadius: 10,
    fontSize: 18,
  },
  components: {
    Button: {
      algorithm: true,
    },
    Card: {
      algorithm: true,
    },
    Tabs: {
      algorithm: true,
    },
  },
};

const App = () => {
  const { dark } = useDark();
  const { compress } = useCompress();
  const themeSetting = useMemo(() => {
    const setting = dark ? darkTheme : lightTheme;
    const algorithm = [];
    if (dark) {
      algorithm.push(theme.darkAlgorithm);
    }
    if (compress) {
      algorithm.push(theme.compactAlgorithm);
    }

    return { ...setting, algorithm };
  }, [dark, compress]);

  return (
    <ConfigProvider theme={themeSetting}>
      <Outlet />
    </ConfigProvider>
  );
};

export default App;
