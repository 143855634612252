import {
  BgColorsOutlined,
  BulbTwoTone,
  CompressOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import { Button, Divider, Space, Switch } from "antd";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { darkTheme, lightTheme } from "../pages/App";
import { useCompress, useDark } from "../store/store";
import { clearUserToken, getUserToken } from "../utils/UserTokenUtils";
import Logo from "./Logo";
import Username from "./Username";

const SystemHeader = () => {
  const { dark, setDark } = useDark();
  const navigate = useNavigate();
  const location = useLocation();
  const { compress, setCompress } = useCompress();
  const color = useMemo(() => {
    return dark ? darkTheme.token.colorPrimary : lightTheme.token.colorPrimary;
  }, [dark]);
  const backgroundColor = useMemo(() => {
    return dark ? lightTheme.token.colorPrimary : darkTheme.token.colorPrimary;
  }, [dark]);
  const userToken = getUserToken();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <Space wrap>
        <Logo />
        <div
          style={{
            fontSize: "2rem",
            margin: 0,
            color: color,
            fontWeight: "bold",
          }}>
          Fusion Code
        </div>
      </Space>
      <div>
        <Space wrap>
          <Username />
          <Button
            hidden={!userToken?.admin || location.pathname.includes("admin")}
            type="primary"
            onClick={() => navigate("/admin")}>
            后台
          </Button>
          <Button
            hidden={!userToken?.admin || !location.pathname.includes("admin")}
            type="primary"
            danger
            onClick={() => navigate("/")}>
            返回
          </Button>
          <Button
            hidden={!!userToken}
            type="primary"
            onClick={() => navigate("/user")}>
            登录
          </Button>
          <Button
            hidden={!userToken}
            type="primary"
            danger
            onClick={() => {
              clearUserToken();
              navigate("/user");
            }}>
            退出
          </Button>
          <Divider type="vertical" />
          <Switch
            autoFocus
            value={dark}
            onChange={(checked) => setDark(checked)}
            style={{ backgroundColor: backgroundColor }}
            checkedChildren={<BulbTwoTone twoToneColor={backgroundColor} />}
            unCheckedChildren={<BgColorsOutlined />}
          />
          <Button
            type="text"
            icon={compress ? <ExpandOutlined /> : <CompressOutlined />}
            onClick={() => setCompress(!compress)}
          />
        </Space>
      </div>
    </div>
  );
};

export default SystemHeader;
