import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  deleteStack,
  getStack,
  getStackList,
  saveStack,
} from "../api/ProjectStackAPI";
import { getGroupList } from "../api/TemplateGroupAPI";
import { PageData } from "../interface/System";
import { ProjectStack, TemplateGroup } from "../interface/Template";
import { errorHandler } from "../utils/RequestUtils";

const ProjectStackTable = () => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<PageData<ProjectStack>>();
  const [groups, setGroups] = useState<TemplateGroup[]>([]);
  const [selectedStack, setSelectedStack] = useState<ProjectStack>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPageData();
    getGroupList()
      .then((res) => {
        setGroups(res.content);
      })
      .catch(errorHandler);
  }, []);

  useEffect(() => {
    if (selectedStack) {
      form.setFieldsValue({ ...selectedStack });
    } else {
      form.resetFields();
    }
  }, [form, selectedStack, isModalVisible]);

  const fetchPageData = async (page: number = 1, size: number = 20) => {
    setLoading(true);
    try {
      const response = await getStackList(page, size);
      setPageData(response);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const showAddModal = () => {
    setSelectedStack(undefined);
    setIsModalVisible(true);
  };

  const showEditModal = async (id: number) => {
    setLoading(true);
    try {
      const response = await getStack(id);
      setSelectedStack(response);
      setIsModalVisible(true);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    try {
      if (selectedStack) {
        const updatedStack = { ...selectedStack, ...values };
        await saveStack(updatedStack);
        message.success("Stack updated successfully.");
      } else {
        await saveStack(values);
        message.success("Stack added successfully.");
      }
      setIsModalVisible(false);
      fetchPageData();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteStack(id);
      message.success("Stack deleted successfully.");
      fetchPageData();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Cost", dataIndex: "cost", key: "cost" },
    { title: "SignCost", dataIndex: "signCost", key: "signCost" },
    { title: "RemarkCost", dataIndex: "remarkCost", key: "remarkCost" },
    { title: "Discount", dataIndex: "discount", key: "discount" },
    {
      title: "Enable",
      dataIndex: "enable",
      key: "enable",
      render: (enable: boolean) => {
        return <Switch checked={enable} disabled />;
      },
    },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Groups",
      dataIndex: "groupIds",
      key: "groupIds",
      render: (groupIds: number[]) => {
        return groups
          .filter((group) => groupIds.includes(group.id))
          .map((param) => <Tag>{param.name}</Tag>);
      },
    },
    {
      title: (
        <Space>
          <div>Actions</div>
          <Button type="primary" onClick={showAddModal}>
            Add
          </Button>
        </Space>
      ),
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: ProjectStack) => (
        <div>
          <Button type="link" onClick={() => showEditModal(record.id)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        dataSource={pageData?.content}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        style={{ marginTop: 16, textAlign: "right" }}
        total={pageData?.total}
        current={pageData?.page}
        pageSize={pageData?.size}
        showSizeChanger
        showQuickJumper
        onChange={fetchPageData}
      />
      <Modal
        open={isModalVisible}
        title={selectedStack ? "Edit" : "Add"}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            Save
          </Button>,
        ]}>
        <Form form={form} onFinish={handleSave}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Cost"
            rules={[{ required: true, message: "Please enter a cost" }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="signCost"
            label="SignCost"
            rules={[{ required: true, message: "Please enter a signCost" }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="remarkCost"
            label="RemarkCost"
            rules={[{ required: true, message: "Please enter a remarkCost" }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="discount"
            label="Discount"
            rules={[{ required: true, message: "Please enter a discount" }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="enable" label="Enable" valuePropName="checked">
            <Switch defaultValue={false} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="groupIds" label="Groups">
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              options={groups.map((group) => {
                return { label: group.name, value: group.id };
              })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectStackTable;
