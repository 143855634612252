import { CopyOutlined, EyeOutlined } from "@ant-design/icons";
import { Popover, Space, message } from "antd";
import copy from "clipboard-copy";

const tips = {
  project: {
    name: "jdbc",
    basePackage: "com.test",
    author: "Yohann",
  },
  database: {
    name: "singer_competition",
    charset: "utf8mb4",
  },
  tables: [
    {
      name: "competition",
      engine: "InnoDB",
      charset: "utf8mb4",
      comment: "比赛表",
      display: true,
      displayName: "比赛",
      actions: ["Search", "Add", "Update", "Delete"],
      columns: [
        {
          name: "user_id",
          type: "INT",
          length: 11,
          primary: false,
          auto: false,
          notnull: true,
          unique: false,
          initial: "'0'",
          comment: "userId",
          reference: "user.username",
          formType: "Select",
          queryType: "Select",
          displayName: "用户",
        },
      ],
    },
  ],
  table: {
    name: "competition",
    engine: "InnoDB",
    charset: "utf8mb4",
    comment: "比赛表",
    display: true,
    displayName: "比赛",
    actions: ["Search", "Add", "Update", "Delete"],
    className: "Competition",
    objectName: "competition",
    references: [
      {
        tableName: "user",
        className: "User",
        objectName: "user",
        referLabel: "theme",
        referValue: "id",
        referField: "competition_id",
      },
    ],
    dependencies: [
      {
        tableName: "user",
        className: "User",
        objectName: "user",
        referLabel: "username",
        referValue: "id",
        referField: "user_id",
      },
    ],
  },
  columns: [
    {
      name: "user_id",
      type: "INT",
      length: 11,
      primary: false,
      auto: false,
      notnull: true,
      unique: false,
      initial: "'0'",
      comment: "userId",
      reference: "user.username",
      formType: "Select",
      queryType: "None",
      displayName: "用户",
      fieldName: "userId",
      fieldType: "Integer",
      getter: "getUserId",
      setter: "setUserId",
    },
  ],
};

const FreeMarkerTips = () => {
  return (
    <Popover
      content={
        <div>
          <pre
            style={{
              marginLeft: "100px",
              marginRight: "100px",
              maxHeight: "60vh",
              overflow: "auto",
            }}>
            <code>{JSON.stringify(tips, null, 4)}</code>
          </pre>
          <style>
            {`
                        ::-webkit-scrollbar {
                            display: none;
                        }
                    `}
          </style>
        </div>
      }
      title={
        <Space>
          <div>Data Model(table&columns are table level)</div>
          <CopyOutlined
            onClick={() => {
              copy(JSON.stringify(tips, null, 4));
              message.success("Copied to clipboard");
            }}
          />
        </Space>
      }>
      <EyeOutlined />
    </Popover>
  );
};

export default FreeMarkerTips;
