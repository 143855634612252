import {
  CodeOutlined,
  CoffeeOutlined,
  ConsoleSqlOutlined,
  FileMarkdownOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Card, Modal, Space, Tree } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import java from "react-syntax-highlighter/dist/esm/languages/hljs/java";
import javascript from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import sql from "react-syntax-highlighter/dist/esm/languages/hljs/sql";
import typescript from "react-syntax-highlighter/dist/esm/languages/hljs/typescript";
import xml from "react-syntax-highlighter/dist/esm/languages/hljs/xml";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
import {
  atomOneDark as dracula,
  githubGist,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { getPreview, postPreview } from "../api/SourceCodeAPI";
import { DefinitionContext } from "../interface/Definition";
import { SourceCodeNode } from "../interface/System";
import { useDark } from "../store/store";
import { errorHandler } from "../utils/RequestUtils";

const { DirectoryTree } = Tree;

interface PreviewProps {
  open: boolean;
  onClosed: () => void;
  projectId?: number;
  context?: DefinitionContext;
}

const SourceCodePreview: React.FC<PreviewProps> = ({
  open,
  onClosed,
  projectId,
  context,
}) => {
  SyntaxHighlighter.registerLanguage("javascript", javascript);
  SyntaxHighlighter.registerLanguage("typescript", typescript);
  SyntaxHighlighter.registerLanguage("java", java);
  SyntaxHighlighter.registerLanguage("yaml", yaml);
  SyntaxHighlighter.registerLanguage("xml", xml);
  SyntaxHighlighter.registerLanguage("sql", sql);
  SyntaxHighlighter.registerLanguage("json", json);

  const { dark } = useDark();
  const [sourceCode, setSourceCode] = useState<SourceCodeNode>();
  const [file, setFile] = useState<SourceCodeNode | undefined>();

  const [contentMap, treeNodes] = useMemo(() => {
    const contentMap = new Map<string, SourceCodeNode | undefined>();
    const renderTreeNodes = (nodes: SourceCodeNode[]): React.ReactNode => {
      return nodes
        .sort((a, b) => {
          if (a.file && !b.file) {
            return 1;
          }
          if (!a.file && b.file) {
            return -1;
          }
          return a.name.localeCompare(b.name);
        })
        .map((node) => {
          if (node.file) {
            contentMap.set(node.path, node);
            const filename = node.name;
            let icon = <CodeOutlined />;
            if (filename.endsWith("sql")) {
              icon = <ConsoleSqlOutlined />;
            } else if (filename.endsWith("java")) {
              icon = <CoffeeOutlined />;
            } else if (filename.endsWith("ml")) {
              icon = <FileMarkdownOutlined />;
            } else if (filename.endsWith("json")) {
              icon = <FileTextOutlined />;
            }

            return (
              <Tree.TreeNode
                title={node.name}
                key={node.path}
                isLeaf
                icon={icon}
              />
            );
          }
          return (
            <Tree.TreeNode title={node.name} key={node.path}>
              {renderTreeNodes(node.next)}
            </Tree.TreeNode>
          );
        });
    };
    const treeNodes = renderTreeNodes(sourceCode ? [sourceCode] : []);
    return [contentMap, treeNodes];
  }, [sourceCode]);

  useEffect(() => {
    if (!open) {
      return;
    }
    let promise;
    if (projectId) {
      promise = getPreview(projectId);
    } else if (context) {
      promise = postPreview(context);
    }

    if (promise) {
      promise
        .then((res) => {
          setSourceCode(res);
        })
        .catch((err) => {
          errorHandler(err);
          onClosed();
        });
    }
    setFile(undefined);
  }, [projectId, context, open, onClosed]);

  const handleNodeSelect = (selectedKeys: React.Key[]) => {
    const key = selectedKeys[0] as string;
    const selected = contentMap.get(key);
    if (selected) {
      setFile(selected);
    }
  };

  return (
    <Modal
      open={open}
      footer=""
      closable={false}
      width={"70vw"}
      title="Source Code Preview"
      onCancel={onClosed}>
      <Space direction="horizontal" align="start">
        <div>
          <DirectoryTree
            onSelect={handleNodeSelect}
            showIcon
            style={{
              width: "20vw",
              overflow: "auto",
              height: "61.5vh",
              borderRadius: 10,
            }}>
            {treeNodes}
          </DirectoryTree>
          <style>
            {`
                ::-webkit-scrollbar {
                    display: none;
                }
              `}
          </style>
        </div>
        <div style={{ width: "47vw" }}>
          <Card title={file?.name || "..."} bordered={false}>
            <div style={{ overflow: "auto", height: "50vh" }}>
              {file?.content && (
                <SyntaxHighlighter
                  showLineNumbers
                  wrapLines
                  style={dark ? dracula : githubGist}>
                  {file?.content}
                </SyntaxHighlighter>
              )}
            </div>
            <style>
              {`
                ::-webkit-scrollbar {
                    display: none;
                }
              `}
            </style>
          </Card>
        </div>
      </Space>
    </Modal>
  );
};

export default SourceCodePreview;
