import { Layout, Menu } from "antd";
import { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import SystemHeader from "../components/SystemHeader";
import { useDark } from "../store/store";
import { darkTheme, lightTheme } from "./App";
const { Header, Sider, Content } = Layout;

const AdminMenu = () => {
  const { dark } = useDark();
  const location = useLocation();

  const menu = useMemo(() => {
    const theme = dark ? darkTheme.token : lightTheme.token;

    return (
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            height: "12vh",
            margin: "1vh",
            padding: "1vh",
            borderRadius: 10,
            backgroundColor: theme.colorBgBase,
            boxShadow: "0 1px 2px" + theme.colorPrimary,
          }}>
          <SystemHeader />
        </Header>
        <Layout style={{ height: "86vh", border: "none" }}>
          <Sider
            width="15vw"
            style={{
              overflow: "auto",
              backgroundColor: theme.colorBgBase,
            }}>
            <Menu
              defaultSelectedKeys={[location.pathname]}
              style={{
                margin: "1vw",
                padding: "1vw",
                fontSize: "20px",
                borderRadius: 10,
                height: "83vh",
                boxShadow: "1px -1px 1px" + theme.colorPrimary,
              }}>
              <Menu.Item key="/admin/definition">
                <Link to="/admin/definition">Project</Link>
              </Menu.Item>
              <Menu.Item key="stack">
                <Link to="/admin/stack">Stack</Link>
              </Menu.Item>
              <Menu.Item key="/admin/group">
                <Link to="/admin/group">Group</Link>
              </Menu.Item>
              <Menu.Item key="/admin/template">
                <Link to="/admin/template">Templates</Link>
              </Menu.Item>
              <Menu.Item key="/admin/param">
                <Link to="/admin/param">Params</Link>
              </Menu.Item>
              <Menu.Item key="/admin/user">
                <Link to="/admin/user">Users</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Content
            style={{
              padding: "1vw",
              backgroundColor: theme.colorBgBase,
            }}>
            <div
              style={{
                overflow: "auto",
                height: "83vh",
                border: "none",
                borderRadius: 10,
                boxShadow: "-1px -1px 1px" + theme.colorPrimary,
              }}>
              <Outlet />
            </div>
            <style>
              {`
                            ::-webkit-scrollbar {
                                display: none;
                            }
                        `}
            </style>
          </Content>
        </Layout>
      </Layout>
    );
  }, [dark, location.pathname]);

  return menu;
};

export default AdminMenu;
