import { create } from "zustand";

type DarkState = {
  dark: boolean;
  setDark: (dark: boolean) => void;
};

export const useDark = create<DarkState>((set) => ({
  dark: true,
  setDark: (dark) => set({ dark }),
}));

type CompressState = {
  compress: boolean;
  setCompress: (compress: boolean) => void;
};

export const useCompress = create<CompressState>((set) => ({
  compress: false,
  setCompress: (compress) => set({ compress }),
}));
