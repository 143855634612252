import { DefinitionContext } from "../interface/Definition";
import { SourceCodeNode } from "../interface/System";
import { request, responseHandler } from "../utils/RequestUtils";

const requestMapping = "/sourceCode";

export const getPreview = async (id: number): Promise<SourceCodeNode> => {
  return request.get(requestMapping + "/preview/" + id).then(responseHandler);
};

export const postPreview = async (
  body: DefinitionContext
): Promise<SourceCodeNode> => {
  return request.post(requestMapping + "/preview", body).then(responseHandler);
};

export const exportProject = async (id: number) => {
  return request.get(requestMapping + "/export/" + id).then(responseHandler);
};

export const cloneProject = async (id: number | string) => {
  return request.get(requestMapping + "/clone/" + id).then(responseHandler);
};
