import { Divider, Popover } from "antd";
import { useState } from "react";
import { getCurrentUser } from "../api/UserAPI";
import { User } from "../interface/System";
import { errorHandler } from "../utils/RequestUtils";
import { getUserToken } from "../utils/UserTokenUtils";
import UserInfo from "./UserInfo";
import { SketchOutlined } from "@ant-design/icons";

const Username = () => {
  const userToken = getUserToken();
  const [user, setUser] = useState<User>();
  const isVip =
    new Date(user?.expiredDate || "2020-01-01 00:00:00") > new Date();

  return userToken ? (
    <>
      <Popover
        content={() => UserInfo(user)}
        onOpenChange={(open) => {
          if (open) {
            getCurrentUser()
              .then((res) => setUser(res))
              .catch(errorHandler);
          }
        }}>
        {userToken.email} <SketchOutlined hidden={!isVip} />
      </Popover>
      <Divider type="vertical" />
    </>
  ) : (
    <></>
  );
};

export default Username;
