import { useMemo } from "react";
import { darkTheme, lightTheme } from "../pages/App";
import { useDark } from "../store/store";

const Logo = () => {
  const { dark } = useDark();
  const theme = useMemo(() => {
    return dark ? darkTheme.token.colorPrimary : lightTheme.token.colorPrimary;
  }, [dark]);

  return (
    <div
      style={{
        display: "block",
        width: "8vh",
        height: "8vh",
        border: "5px solid " + theme,
        borderRadius: "8px",
      }}>
      <div
        style={{
          fontFamily: "JetBrains Mono, monospace",
          fontSize: "40px",
          fontWeight: "bold",
          color: theme,
          marginTop: "-0.5vh",
          marginLeft: "0.5vh",
        }}>
        FC
      </div>
      <div
        style={{
          width: "2.5vh",
          height: "0.5vh",
          backgroundColor: theme,
          borderRadius: "4px",
          marginTop: "-0.5vh",
          marginLeft: "0.5vh",
        }}
      />
    </div>
  );
};

export default Logo;
