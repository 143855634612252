import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import SystemHeader from "../components/SystemHeader";
import { useDark } from "../store/store";
import { darkTheme, lightTheme } from "./App";
const { Header, Content } = Layout;

const FusionMenu = () => {
  const { dark } = useDark();
  const theme = dark ? darkTheme.token : lightTheme.token;

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          height: "12vh",
          margin: "1vh",
          padding: "1vh",
          borderRadius: 10,
          backgroundColor: theme.colorBgBase,
        }}>
        <SystemHeader />
      </Header>
      <Layout style={{ height: "86vh", border: "none" }}>
        <Content
          style={{
            padding: "1vw",
            backgroundColor: theme.colorBgBase,
          }}>
          <div
            style={{
              overflow: "auto",
              height: "83vh",
              border: "none",
              borderRadius: 10,
            }}>
            <Outlet />
          </div>
          <style>
            {`
                  ::-webkit-scrollbar {
                      display: none;
                  }
              `}
          </style>
        </Content>
      </Layout>
    </Layout>
  );
};

export default FusionMenu;
