import {
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Space,
  Table,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import {
  deleteParam,
  getParam,
  getParamList,
  saveParam,
} from "../api/ContextParamAPI";
import { PageData } from "../interface/System";
import { ContextParam } from "../interface/Template";
import { errorHandler } from "../utils/RequestUtils";
import FreeMarkerTips from "./FreeMarkerTips";

const ContextParamTable = () => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<PageData<ContextParam>>();
  const [selectedParam, setSelectedParam] = useState<ContextParam>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPageData();
  }, []);

  useEffect(() => {
    if (selectedParam) {
      form.setFieldsValue({ ...selectedParam });
    } else {
      form.resetFields();
    }
  }, [form, selectedParam, isModalVisible]);

  const fetchPageData = async (page: number = 1, size: number = 10) => {
    setLoading(true);
    try {
      const response = await getParamList(page, size);
      setPageData(response);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const showAddModal = () => {
    setSelectedParam(undefined);
    setIsModalVisible(true);
  };

  const showEditModal = async (id: number) => {
    setLoading(true);
    try {
      const response = await getParam(id);
      setSelectedParam(response);
      setIsModalVisible(true);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    try {
      if (selectedParam) {
        const updatedParam = { ...selectedParam, ...values };
        await saveParam(updatedParam);
        message.success("Param updated successfully.");
      } else {
        await saveParam(values);
        message.success("Param added successfully.");
      }
      setIsModalVisible(false);
      fetchPageData();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteParam(id);
      message.success("Param deleted successfully.");
      fetchPageData();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Param", dataIndex: "param", key: "param" },
    { title: "Context", dataIndex: "context", key: "context" },
    {
      title: (
        <Space>
          <>Actions</>
          <Button type="primary" onClick={showAddModal}>
            Add
          </Button>
        </Space>
      ),
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: ContextParam) => (
        <div>
          <Button type="link" onClick={() => showEditModal(record.id)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        dataSource={pageData?.content}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        style={{ marginTop: 16, textAlign: "right" }}
        total={pageData?.total}
        current={pageData?.page}
        pageSize={pageData?.size}
        showSizeChanger
        showQuickJumper
        onChange={fetchPageData}
      />
      <Modal
        open={isModalVisible}
        title={
          <Space>
            <div>{selectedParam ? "Edit" : "Add"}</div>
            <FreeMarkerTips />
          </Space>
        }
        onCancel={() => setIsModalVisible(false)}
        footer={
          <Button
            key="save"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            Save
          </Button>
        }>
        <Form form={form} onFinish={handleSave}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="param"
            label="Param"
            rules={[{ required: true, message: "Please enter a param" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="context"
            label="Context"
            rules={[{ required: true, message: "Please enter a context" }]}>
            <TextArea rows={5} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ContextParamTable;
