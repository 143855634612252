import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Descriptions, Space, message } from "antd";
import copy from "clipboard-copy";
import { User } from "../interface/System";

const UserInfo = (user?: User) => {
  const onCopy = () => {
    const link = "http://" + window.location.host + "/#/user/" + user?.id;
    copy(link);
    message.success(`复制成功，邀请注册获取${user?.recommendPoints}积分`);
  };
  return (
    <Descriptions column={1} style={{ width: "10vw" }}>
      <Descriptions.Item label="赠送积分">
        <Space>
          {user?.freePoints}
          <Button type="link" icon={<ShareAltOutlined />} onClick={onCopy} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="充值积分">
        {user?.rechargePoints}
      </Descriptions.Item>
    </Descriptions>
  );
};
export default UserInfo;
