import {
  DeleteOutlined,
  FileZipTwoTone,
  FireTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Popconfirm, Space, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { deleteProject, getProjects } from "../api/FusionProjectAPI";
import { ProjectDto } from "../interface/Definition";
import { darkTheme, lightTheme } from "../pages/App";
import { useCompress, useDark } from "../store/store";
import { errorHandler, request } from "../utils/RequestUtils";
import { getUserToken } from "../utils/UserTokenUtils";
import StackDescriptions from "./StackDescriptions";

const defaultSize = {
  width: "17vw",
  padding: "1px",
  margin: "1.1vw",
  height: "11vw",
};

const smallSize = {
  width: "13.5vw",
  padding: "1px",
  margin: "1.1vw",
  height: "8vw",
};

const FusionProject = () => {
  const { dark } = useDark();
  const { compress } = useCompress();
  const navigate = useNavigate();
  const theme = dark ? darkTheme.token : lightTheme.token;
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const userToken = getUserToken();

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    getProjects()
      .then((res) => {
        setProjects(res);
      })
      .catch(errorHandler);
  };

  const onDeleteProject = (id: number) => {
    deleteProject(id)
      .then(() => {
        message.success("删除成功");
        getList();
      })
      .catch(errorHandler);
  };

  const onExport = (id: number) => {
    request
      .get("/sourceCode/export/" + id, { responseType: "blob" })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.includes("application/json")) {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const json = JSON.parse(reader.result as string);
                resolve(json);
              } catch (error) {
                reject(error);
              }
            };
            reader.onerror = reject;
            reader.readAsText(response.data);
          })
            .then((result: any) => {
              const success = result.success;
              const message = result.message;

              if (!success) {
                throw new Error(message);
              }
            })
            .catch(errorHandler);
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/zip" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "project.zip");
          document.body.appendChild(link);
          link.click();
          return;
        }
      })
      .catch(errorHandler);
  };

  const projectCards = useMemo(() => {
    return projects.map((project) => {
      const cost =
        parseInt((project.discount * project.cost).toFixed()) +
        project.signCost +
        project.remarkCost;

      return (
        <div className="project-card">
          <Badge
            color="gold"
            count={cost}
            offset={[-30, 30]}
            overflowCount={999999999}>
            <Card
              title={
                <div onClick={() => navigate("/fusion/" + project.id)}>
                  {project.name}
                </div>
              }
              headStyle={project.shared ? { color: theme.colorLink } : {}}
              extra={
                <Space>
                  <Popconfirm
                    title="导出"
                    description={"总花费" + cost}
                    onConfirm={() => onExport(project.id)}
                    okText="确认"
                    cancelText="取消">
                    <Button
                      hidden={project.shared}
                      type="text"
                      size="small"
                      icon={
                        <FileZipTwoTone twoToneColor={theme.colorPrimary} />
                      }
                    />
                  </Popconfirm>
                  <Button
                    hidden={project.shared}
                    type="text"
                    size="small"
                    danger
                    icon={
                      <Popconfirm
                        title="删除"
                        onConfirm={() => onDeleteProject(project.id)}
                        okText="确认"
                        cancelText="取消">
                        <DeleteOutlined />
                      </Popconfirm>
                    }
                    onClick={() => {}}
                  />
                  <div hidden={!project.shared}>
                    <FireTwoTone twoToneColor={theme.colorPrimary} />
                    <span color={theme.colorPrimary}>
                      {project.cloneCount > 99 ? "99+" : project.cloneCount}
                    </span>
                  </div>
                </Space>
              }
              style={compress ? smallSize : defaultSize}>
              <div onClick={() => navigate("/fusion/" + project.id)}>
                {StackDescriptions(project.stackDesc)}
              </div>
            </Card>
          </Badge>
        </div>
      );
    });
  }, [compress, projects]);

  return (
    <Space wrap>
      <div hidden={!userToken} className="project-card">
        <Card
          style={
            compress
              ? {
                  ...smallSize,
                  fontSize: "70px",
                  fontWeight: "bolder",
                  textAlign: "center",
                }
              : {
                  ...defaultSize,
                  fontSize: "90px",
                  fontWeight: "bolder",
                  textAlign: "center",
                }
          }
          onClick={() => navigate("/fusion")}>
          <PlusOutlined />
        </Card>
      </div>
      {projectCards}
    </Space>
  );
};

export default FusionProject;
