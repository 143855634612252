import {
  RouteObject,
  RouterProvider,
  createHashRouter,
} from "react-router-dom";
import ContextParamTable from "../components/ContextParamTable";
import DefinitionContextEdit from "../components/DefinitionContextEdit";
import DefinitionContextTable from "../components/DefinitionContextTable";
import FusionProject from "../components/FusionProject";
import ProjectStackTable from "../components/ProjectStackTable";
import TemplateFileTable from "../components/TemplateFileTable";
import TemplateGroupTable from "../components/TemplateGroupTable";
import AdminMenu from "../pages/AdminMenu";
import App from "../pages/App";
import FusionMenu from "../pages/FusionMenu";
import UserPage from "../pages/UserPage";
import UserTable from "../components/UserTable";

export const MenuRouter = () => {
  const wrapperRouterProps: RouteObject[] = [
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "admin",
          element: <AdminMenu />,
          children: [
            { path: "", element: <DefinitionContextTable /> },
            { path: "user", element: <UserTable /> },
            { path: "param", element: <ContextParamTable /> },
            { path: "template", element: <TemplateFileTable /> },
            { path: "group", element: <TemplateGroupTable /> },
            { path: "stack", element: <ProjectStackTable /> },
            { path: "definition", element: <DefinitionContextTable /> },
            { path: "definitionEdit/:id?", element: <DefinitionContextEdit /> },
          ],
        },
        {
          path: "/",
          element: <FusionMenu />,
          children: [
            { path: "", element: <FusionProject /> },
            { path: "fusion/:id?", element: <DefinitionContextEdit /> },
          ],
        },
        { path: "user/:referrerId?", element: <UserPage /> },
      ],
    },
  ];

  return <RouterProvider router={createHashRouter(wrapperRouterProps)} />;
};
