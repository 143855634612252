import { jwtDecode } from "jwt-decode";
import { UserToken } from "../interface/System";

export const getUserToken = () => {
  const certificate = sessionStorage.getItem("certificate");
  if (certificate) {
    const decode = jwtDecode(certificate) as UserToken;
    return decode;
  }
};

export const clearUserToken = () => {
  sessionStorage.removeItem("certificate");
  sessionStorage.clear();
};
