import { PageData } from "../interface/System";
import { ContextParam } from "../interface/Template";
import { request, responseHandler } from "../utils/RequestUtils";

const requestMapping = "/contextParam";

export const getParamList = async (
  page?: number,
  size?: number
): Promise<PageData<ContextParam>> => {
  return request
    .get(requestMapping, { params: { page, size } })
    .then(responseHandler);
};

export const getParam = async (id: number): Promise<ContextParam> => {
  return request.get(requestMapping + "/" + id).then(responseHandler);
};

export const saveParam = async (body: ContextParam) => {
  return request.post(requestMapping, body).then(responseHandler);
};

export const deleteParam = async (id: number) => {
  return request.delete(requestMapping + "/" + id).then(responseHandler);
};
