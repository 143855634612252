import { ProFormCaptcha } from "@ant-design/pro-components";
import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { sendRegisterCode } from "../api/EmailAPI";
import { register } from "../api/FusionProjectAPI";
import { errorHandler } from "../utils/RequestUtils";

const emailPattren = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterForm = () => {
  const { referrerId } = useParams<{ referrerId: string }>();
  const [form] = Form.useForm();
  const [codeItem, setCodeItem] = useState(false);
  const navigate = useNavigate();
  const onFinish = () => {
    register(form.getFieldsValue())
      .then(() => {
        message.success("注册成功");
        navigate("/");
      })
      .catch(errorHandler);
  };

  const onInputEmail = (email: string) => {
    setCodeItem(emailPattren.test(email));
  };

  return (
    <Form form={form} onFinish={onFinish} layout="horizontal" size="large">
      <Form.Item
        label="账号"
        name="email"
        rules={[
          {
            required: true,
            message: "请输入邮箱",
            pattern: emailPattren,
          },
        ]}
      >
        <Input
          placeholder="请输入邮箱"
          onChange={(e) => onInputEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="密码"
        name="password"
        rules={[{ required: true, message: "请输入密码" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label="推荐码" name="referrerId" initialValue={referrerId}>
        <Input placeholder="推荐码（选填）" disabled={!!referrerId} />
      </Form.Item>{" "}
      <ProFormCaptcha
        hidden={!codeItem}
        width={300}
        placeholder={"请输入验证码"}
        captchaTextRender={(timing, count) => {
          const email = form.getFieldValue("email");
          if (email && timing) {
            return `${count} ${"获取验证码"}`;
          }
          return "获取验证码";
        }}
        name="restCode"
        label="验证码"
        rules={[
          {
            required: true,
            message: "请输入验证码！",
          },
        ]}
        onGetCaptcha={async () => {
          const email = form.getFieldValue("email");
          form.setFieldValue("restCode", undefined);
          if (email) {
            sendRegisterCode(email)
              .then(() => message.success("获取验证码成功！"))
              .catch(errorHandler);
          }
        }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          注册
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
