import { DefinitionContext, Project } from "../interface/Definition";
import { PageData } from "../interface/System";
import { request, responseHandler } from "../utils/RequestUtils";

const requestMapping = "/definitionContext";

export const getDefinitionList = async (
  page?: number,
  size?: number
): Promise<PageData<Project>> => {
  return request
    .get(requestMapping, { params: { page, size } })
    .then(responseHandler);
};

export const getDefinition = async (id: string): Promise<DefinitionContext> => {
  return request.get(requestMapping + "/getProject/" + id).then(responseHandler);
};

export const saveDefinition = async (body: DefinitionContext) => {
  return request.post(requestMapping, body).then(responseHandler);
};

export const deleteDefinition = async (id: number) => {
  return request.delete(requestMapping + "/" + id).then(responseHandler);
};
