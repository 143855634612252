import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Space,
  Switch,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { getUser, getUserList, saveUser } from "../api/UserAPI";
import { PageData, User } from "../interface/System";
import { errorHandler } from "../utils/RequestUtils";
import FreeMarkerTips from "./FreeMarkerTips";

const UserTable = () => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<PageData<User>>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPageData();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue({ ...selectedUser });
    } else {
      form.resetFields();
    }
  }, [form, selectedUser, isModalVisible]);

  const fetchPageData = async (page: number = 1, size: number = 10) => {
    setLoading(true);
    try {
      const response = await getUserList(page, size);
      setPageData(response);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const showAddModal = () => {
    setSelectedUser(undefined);
    setIsModalVisible(true);
  };

  const showEditModal = async (id: number) => {
    setLoading(true);
    try {
      const response = await getUser(id);
      setSelectedUser(response);
      setIsModalVisible(true);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    try {
      if (selectedUser) {
        const updatedParam = { ...selectedUser, ...values };
        await saveUser(updatedParam);
        message.success("User updated successfully.");
      } else {
        await saveUser(values);
        message.success("User added successfully.");
      }
      setIsModalVisible(false);
      fetchPageData();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "FreePoints", dataIndex: "freePoints", key: "freePoints" },
    {
      title: "RechargePoints",
      dataIndex: "rechargePoints",
      key: "rechargePoints",
    },
    {
      title: "RecommendPoints",
      dataIndex: "recommendPoints",
      key: "recommendPoints",
    },
    { title: "ExpiredDate", dataIndex: "expiredDate", key: "expiredDate" },
    { title: "LoginDate", dataIndex: "loginDate", key: "loginDate" },
    { title: "Context", dataIndex: "context", key: "context" },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (admin: boolean) => <Switch checked={admin} disabled />,
    },
    {
      title: (
        <Space>
          <>Actions</>
          <Button type="primary" onClick={showAddModal}>
            Add
          </Button>
        </Space>
      ),
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: User) => (
        <div>
          <Button type="link" onClick={() => showEditModal(record.id)}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        dataSource={pageData?.content}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        style={{ marginTop: 16, textAlign: "right" }}
        total={pageData?.total}
        current={pageData?.page}
        pageSize={pageData?.size}
        showSizeChanger
        showQuickJumper
        onChange={fetchPageData}
      />
      <Modal
        open={isModalVisible}
        title={
          <Space>
            <div>{selectedUser ? "Edit" : "Add"}</div>
            <FreeMarkerTips />
          </Space>
        }
        onCancel={() => setIsModalVisible(false)}
        footer={
          <Button
            key="save"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            Save
          </Button>
        }>
        <Form form={form} onFinish={handleSave}>
          <Form.Item name="id" hidden />
          <Form.Item name="referrerId" hidden />
          <Form.Item name="createDate" hidden />
          <Form.Item name="updateDate" hidden />
          <Form.Item name="loginDate" hidden />
          <Form.Item name="expiredDate" hidden />
          <Form.Item name="restCode" hidden />
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please enter a email" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter a password" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="admin" label="Admin" valuePropName="checked">
            <Switch defaultValue={false} />
          </Form.Item>
          <Form.Item
            name="rechargePoints"
            label="RechargePoints"
            rules={[
              { required: true, message: "Please enter a rechargePoints" },
            ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="freePoints"
            label="FreePoints"
            rules={[{ required: true, message: "Please enter a freePoints" }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="recommendPoints"
            label="RecommendPoints"
            rules={[
              { required: true, message: "Please enter a recommendPoints" },
            ]}>
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserTable;
