import "antd/dist/reset.css";
import moment from "moment";
import "moment/locale/zh-cn";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { MenuRouter } from "./router/router";
moment.locale("zh-cn");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MenuRouter />
  </React.StrictMode>
);
