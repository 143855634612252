import {
  DefinitionContext,
  ProjectDto
} from "../interface/Definition";
import { User } from "../interface/System";
import { request, responseHandler } from "../utils/RequestUtils";
import {
  deleteDefinition,
  getDefinition,
  saveDefinition,
} from "./DefinitionContextAPI";

export const getProjects = async (): Promise<ProjectDto[]> => {
  return request.get("/definitionContext/projects").then(responseHandler);
};

export const getProject = async (id: string): Promise<DefinitionContext> => {
  return getDefinition(id);
};

export const saveProject = async (body: DefinitionContext) => {
  return saveDefinition(body);
};

export const deleteProject = async (id: number) => {
  return deleteDefinition(id);
};

export const login = async (
  username: string,
  password: string
): Promise<User> => {
  return request
    .get("login", { params: { username, password } })
    .then(responseHandler);
};

export const logout = async () => {
  return request.get("logout").then(responseHandler);
};

export const register = async (body: User) => {
  return request.post("register", body).then(responseHandler);
};
