import { Button, Pagination, Space, Switch, Table, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteDefinition,
  getDefinitionList,
} from "../api/DefinitionContextAPI";
import { getStackList } from "../api/ProjectStackAPI";
import { Project } from "../interface/Definition";
import { PageData } from "../interface/System";
import { ProjectStack } from "../interface/Template";
import { errorHandler, request } from "../utils/RequestUtils";
import { getUserToken } from "../utils/UserTokenUtils";
import SourceCodePreview from "./SourceCodePreview";

const DefinitionContextTable = () => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<PageData<Project>>();
  const [stacks, setStacks] = useState<ProjectStack[]>([]);
  const [projectId, setProjectId] = useState<number>();
  const userToken = getUserToken();

  useEffect(() => {
    fetchPageData();
    getStackList()
      .then((res) => {
        setStacks(res.content);
      })
      .catch(errorHandler);
  }, []);

  const fetchPageData = async (page: number = 1, size: number = 20) => {
    setLoading(true);
    try {
      const response = await getDefinitionList(page, size);
      setPageData(response);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteDefinition(id);
      message.success("Project deleted successfully.");
      fetchPageData();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const onExport = (id: number) => {
    request
      .get("/sourceCode/export/" + id, { responseType: "blob" })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.includes("application/json")) {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const json = JSON.parse(reader.result as string);
                resolve(json);
              } catch (error) {
                reject(error);
              }
            };
            reader.onerror = reject;
            reader.readAsText(response.data);
          })
            .then((result: any) => {
              const success = result.success;
              const message = result.message;

              if (!success) {
                throw new Error(message);
              }
            })
            .catch(errorHandler);
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/zip" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "project.zip");
          document.body.appendChild(link);
          link.click();
          return;
        }
      })
      .catch(errorHandler);
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: Project) => {
        return record.userId === userToken?.id ? (
          name
        ) : record.shared ? (
          <span style={{ color: "gold", fontWeight: "bolder" }}>{name}</span>
        ) : (
          <span style={{ color: "red", fontWeight: "bolder" }}>{name}</span>
        );
      },
    },
    {
      title: "Shared",
      dataIndex: "shared",
      key: "shared",
      render: (shared: boolean) => <Switch checked={shared} disabled />,
    },
    { title: "BasePackage", dataIndex: "basePackage", key: "basePackage" },
    { title: "Author", dataIndex: "author", key: "author" },
    {
      title: "Stack",
      dataIndex: "stackId",
      key: "stackId",
      render: (stackId: number) => {
        const find = stacks.find((stack) => stack.id === stackId);
        return <Tag>{find?.name}</Tag>;
      },
    },
    {
      title: (
        <Space>
          <div>Actions</div>
          <Link to={"/admin/definitionEdit/"}>
            <Button type="primary">Add</Button>
          </Link>
        </Space>
      ),
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: Project) => (
        <div>
          <Button type="link" onClick={() => onExport(record.id)}>
            Export
          </Button>
          <Button type="link" onClick={() => setProjectId(record.id)}>
            Preview
          </Button>
          <Link to={"/admin/definitionEdit/" + record.id}>
            <Button type="link">Edit</Button>
          </Link>
          <Button
            type="link"
            danger
            onClick={() => handleDelete(record.id)}
            hidden={record.userId !== userToken?.id || record.shared}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        dataSource={pageData?.content}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        style={{ marginTop: 16, textAlign: "right" }}
        total={pageData?.total}
        current={pageData?.page}
        pageSize={pageData?.size}
        showSizeChanger
        showQuickJumper
        onChange={fetchPageData}
      />
      <SourceCodePreview
        open={!!projectId}
        onClosed={() => setProjectId(undefined)}
        projectId={projectId}
      />
    </div>
  );
};

export default DefinitionContextTable;
