import { PageData } from "../interface/System";
import { ProjectStack } from "../interface/Template";
import { request, responseHandler } from "../utils/RequestUtils";

const requestMapping = "/projectStack";

export const getStackList = async (
  page?: number,
  size?: number
): Promise<PageData<ProjectStack>> => {
  return request
    .get(requestMapping, { params: { page, size } })
    .then(responseHandler);
};

export const getEnableStackList = async (): Promise<ProjectStack[]> => {
  return request.get(requestMapping + "/getStacks").then(responseHandler);
};

export const getStack = async (id: number): Promise<ProjectStack> => {
  return request.get(requestMapping + "/" + id).then(responseHandler);
};

export const saveStack = async (body: ProjectStack) => {
  return request.post(requestMapping, body).then(responseHandler);
};

export const deleteStack = async (id: number) => {
  return request.delete(requestMapping + "/" + id).then(responseHandler);
};
