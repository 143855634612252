import { message } from "antd";
import axios from "axios";
import { clearUserToken } from "./UserTokenUtils";

export const BASE_URL = "http://generator.yohann-study.top";

export const request = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use((request) => {
  request.headers.set("Certificate", sessionStorage.getItem("certificate"));
  return request;
});

export const responseHandler = (response: any) => {
  const result = response.data;
  const success = result.success;
  const message = result.message;
  const data = result.data;

  if (!success) {
    throw new Error(message);
  }

  const headers = response.headers;
  const certificate = headers["certificate"];
  if (certificate) {
    sessionStorage.setItem("certificate", certificate);
  }

  return data;
};

export const errorHandler = (error: any) => {
  const msg = error?.message || error;
  if (msg?.startsWith("redirect:")) {
    if (msg?.includes("/user")) {
      clearUserToken();
    }
    const redirectPath = msg.substring(9);
    window.location.replace(redirectPath);
    return;
  }

  message.warning(msg);
};
