import {
  CheckCard,
  ProCard,
  ProFormDependency,
  ProFormSelect,
} from "@ant-design/pro-components";
import {
  ProForm,
  ProFormList,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-form";
import type { ProColumns } from "@ant-design/pro-table";
import { EditableProTable } from "@ant-design/pro-table";
import {
  Button,
  Card,
  Cascader,
  Descriptions,
  Divider,
  Form,
  Popover,
  Select,
  Space,
  Switch,
  Tooltip,
  Tour,
  TourProps,
  message,
} from "antd";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { getDefinition, saveDefinition } from "../api/DefinitionContextAPI";
import { getEnableStackList, getStackList } from "../api/ProjectStackAPI";
import { cloneProject } from "../api/SourceCodeAPI";
import { Column, DefinitionContext, Project } from "../interface/Definition";
import { ProjectStack } from "../interface/Template";
import { errorHandler } from "../utils/RequestUtils";
import { getUserToken } from "../utils/UserTokenUtils";
import SourceCodePreview from "./SourceCodePreview";
import StackDescriptions from "./StackDescriptions";

const mysqlNamingRegex = /^[a-z][a-z0-9_]*$/;
const packageNameRegex = /^[a-z]+(\.[a-z]+)*$/;

const columnTypes = [
  { label: "整数", value: "INT" },
  { label: "可变文本", value: "VARCHAR" },
  { label: "文本", value: "TEXT" },
  { label: "日期", value: "DATE" },
  { label: "日期时间", value: "DATETIME" },
  { label: "布尔", value: "BIT" },
  { label: "数字", value: "DECIMAL" },
];
const formTypes = [
  { label: "隐藏", value: "Hidden" },
  { label: "输入框", value: "Input" },
  { label: "数字", value: "Number" },
  { label: "文本块", value: "TextArea" },
  { label: "开关", value: "Switch" },
  { label: "日期", value: "Date" },
  { label: "日期时间", value: "DateTime" },
  { label: "引用", value: "Refer" },
];
const queryTypes = [
  { label: "无", value: "none" },
  { label: "相等", value: "eq" },
  { label: "大于", value: "gt" },
  { label: "大于等于", value: "ge" },
  { label: "小于", value: "lt" },
  { label: "小于等于", value: "le" },
  { label: "模糊", value: "like" },
  { label: "范围", value: "between" },
];
const actionTypes = [
  { label: "查询", value: "Search" },
  { label: "新增", value: "Add" },
  { label: "更新", value: "Update" },
  { label: "删除", value: "Delete" },
];

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const popTitle = (title: string, message: string) => {
  return <Tooltip title={message}>{title}</Tooltip>;
};

const costPopover = (content: ReactNode, stack: ProjectStack) => {
  const discount = stack.discount;
  return (
    <Popover
      title="花费"
      content={
        <Descriptions column={1} size="small" style={{ width: "10vw" }}>
          <Descriptions.Item label="导出">
            {discount === 1
              ? stack.cost
              : `${stack.cost} * ${discount * 100}% = ${(
                  discount * stack.cost
                ).toFixed()}`}
          </Descriptions.Item>
          <Descriptions.Item label="去广">{stack.signCost}</Descriptions.Item>
          <Descriptions.Item label="注释">{stack.remarkCost}</Descriptions.Item>
        </Descriptions>
      }>
      {content}
    </Popover>
  );
};

const DefinitionContextEdit = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const userToken = getUserToken();
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState<Project>();
  const [stacks, setStacks] = useState<ProjectStack[]>([]);
  const [editableKey, setEditableKey] = useState<React.Key>("");
  const [options, setOptions] = useState<Option[]>([]);
  const stackRef = useRef(null);
  const projectRef = useRef(null);
  const tableRef = useRef(null);
  const columRef = useRef(null);

  const [tour, setTour] = useState<boolean>(false);

  const steps: TourProps["steps"] = [
    {
      title: "技术栈",
      description: "选择需要生成的技术栈",
      target: () => stackRef.current,
    },
    {
      title: "项目",
      description: "配置项目基础信息",
      target: () => projectRef.current,
    },
    {
      title: "表设计",
      description: "可以创建多个表，配置表的基础信息和字段信息",
      target: () => tableRef.current,
    },
    {
      title: "字段设计",
      description:
        "可以为表创建多个字段，并配置字段的详细信息，用于生成各种技术栈的代码",
      target: () => columRef.current,
    },
  ];

  useEffect(() => {
    if (userToken?.admin) {
      getStackList(0, 0)
        .then((res) => {
          setStacks(res.content);
        })
        .catch(errorHandler);
    } else {
      getEnableStackList()
        .then((res) => {
          setStacks(res);
        })
        .catch(errorHandler);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getDefinition(id)
        .then((res) => {
          form.setFieldsValue(res);
          refreshOption(res);
          setProject(res.project);
        })
        .catch(errorHandler);
    }
  }, [id, form]);

  const disabled = useMemo(() => {
    return (
      project?.shared && !userToken?.admin && project?.userId !== userToken?.id
    );
  }, [project, userToken]);

  const refreshOption = (context?: DefinitionContext) => {
    if (!context) {
      context = form.getFieldsValue() as DefinitionContext;
    }
    const tables = context.tables || [];
    if (tables.length === 0) {
      return;
    }

    const options = tables.map((table) => {
      const columns = table.columns || [];
      const children = columns.map((column) => {
        return {
          value: column.name,
          label: column.name,
        };
      });

      return {
        value: table.name,
        label: table.name,
        children: children,
      };
    });
    setOptions(options);
  };

  const checkAndGetForm = () => {
    const context = form.getFieldsValue() as DefinitionContext;
    const project = context.project;
    const database = context.database;
    if (!project.stackId) {
      message.warning("请选择技术栈");
      return;
    }
    if (!project.name) {
      message.warning("请填写项目名称");
      return;
    }
    if (!project.basePackage) {
      message.warning("请填写基础包名");
      return;
    }
    if (!packageNameRegex.test(project.basePackage)) {
      message.warning("基础包名采用xxx.xxx.xxx命名");
      return;
    }
    if (!project.author) {
      message.warning("请填写作者签名");
      return;
    }
    if (!database.name) {
      message.warning("请填写数据库名");
      return;
    }
    if (!mysqlNamingRegex.test(database.name)) {
      message.warning("数据库请采用小写下划线命名");
      return;
    }
    const tables = context.tables || [];
    if (tables.length === 0) {
      message.warning("请添加表设计");
      return;
    }
    for (const table of tables) {
      const tableName = table.name;
      if (!tableName) {
        message.warning("请填写表名");
        return;
      }
      if (!mysqlNamingRegex.test(tableName)) {
        message.warning(`表[${tableName}]请采用小写下划线命名`);
        return;
      }
      if (!table.comment) {
        message.warning(`请填写[${tableName}]的表备注`);
        return;
      }
      if (table.display && !table.displayName) {
        message.warning(`请填写[${tableName}]的展示名称`);
        return;
      }
      const columns = table.columns || [];
      if (columns.length === 0) {
        message.warning("请添加字段设计");
        return;
      }

      for (const column of columns) {
        if (column.id && column.id < 1) {
          Object.assign(column, { id: undefined });
        }
        const columnName = column.name;
        if (!columnName) {
          message.warning(`请填写[${tableName}]的字段名`);
          return;
        }
        if (!mysqlNamingRegex.test(columnName)) {
          message.warning(
            `字段[${tableName}.${columnName}]请采用小写下划线命名`
          );
          return;
        }
        if (!column.type) {
          message.warning(`[${tableName}.${columnName}] 请选择字段类型`);
          return;
        }
        if (!column.formType) {
          message.warning(`[${tableName}.${columnName}] 请选择表单类型`);
          return;
        }
        if (!column.queryType) {
          message.warning(`[${tableName}.${columnName}] 请选择查询类型`);
          return;
        }
        if (!column.displayName) {
          message.warning(`[${tableName}.${columnName}] 请填写展示名称`);
          return;
        }

        if (["VARCHAR", "DECIMAL"].includes(column.type) && !column.length) {
          message.warning(`[${tableName}.${columnName}] 请填写长度`);
          return;
        }
        if (column.primary && !["VARCHAR", "INT"].includes(column.type)) {
          message.warning(
            `[${tableName}.${columnName}] 主键只允许整数/可变文本`
          );
          return;
        }
        if (column.auto && !(column.type === "INT" && column.primary)) {
          message.warning(`[${tableName}.${columnName}] 只有整数主键允许自增`);
          return;
        }
        if (
          ["gt", "ge", "lt", "le", "between"].includes(column.queryType) &&
          !["Number", "Date", "DateTime"].includes(column.formType)
        ) {
          message.warning(
            `[${tableName}.${columnName}] 只有数字/日期支持比较查询`
          );
          return;
        }
        if (column.queryType === "like" && column.formType !== "Input") {
          message.warning(
            `[${tableName}.${columnName}] 只有文本框支持模糊查询`
          );
          return;
        }
        if (column.formType === "Refer" && !column.reference) {
          message.warning(`[${tableName}.${columnName}] 请选择引用的表字段`);
          return;
        }
        if (column.reference?.length > 0 && column.formType !== "Refer") {
          message.warning(`[${tableName}.${columnName}] 请选择引用表单`);
          return;
        }
        if (column.formType === "Refer" && column.queryType !== "eq") {
          message.warning(`[${tableName}.${columnName}] 引用只支持相等匹配`);
          return;
        }
      }

      const primary = columns.filter((column) => column.primary);
      if (primary.length !== 1) {
        message.warning(`请给表[${tableName}]设置一个主键`);
        return;
      }
    }

    return context;
  };

  const onFinish = () => {
    const context = checkAndGetForm();
    if (context) {
      saveDefinition(context)
        .then(() => {
          message.success("项目保存成功");
          navigate("/");
        })
        .catch(errorHandler);
    }
  };

  const onClone = () => {
    if (id) {
      cloneProject(id)
        .then(() => {
          message.success("项目克隆成功");
          navigate("/");
        })
        .catch(errorHandler);
    } else {
      message.warning("当前项目无法克隆");
    }
  };

  const columns: ProColumns<Column>[] = [
    {
      title: "Id",
      dataIndex: "id",
      hideInTable: true,
    },
    {
      title: popTitle("字段名", "采用小写下划线命名"),
      dataIndex: "name",
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: popTitle("字段类型", "定义字段类型，将影响技术栈对应的数据类型"),
      dataIndex: "type",
      initialValue: "INT",
      render: (_, { type }) => {
        return columnTypes.find((item) => item.value === type)?.label;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Select
            value={record?.type}
            onChange={(value) => {
              if (record) {
                record.type = value;
              }
            }}
            options={columnTypes}
          />
        );
      },
    },
    {
      title: popTitle(
        "长度",
        "可变文本和数字类型需要定义长度，可变文本如：255，数字如：10,2"
      ),
      dataIndex: "length",
    },
    {
      title: popTitle("主键", "需要为表选择一个整数/可变字符的主键"),
      dataIndex: "primary",
      initialValue: false,
      render: (_, { primary }) => {
        return <Switch checked={primary} />;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Switch
            checked={record?.primary}
            onChange={(checked) => {
              if (record) {
                record.primary = checked;
              }
            }}
          />
        );
      },
    },
    {
      title: popTitle("自增", "整数主键可以开启数据库自增"),
      dataIndex: "auto",
      initialValue: false,
      render: (_, { auto }) => {
        return <Switch checked={auto} />;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Switch
            checked={record?.auto}
            onChange={(checked) => {
              if (record) {
                record.auto = checked;
              }
            }}
          />
        );
      },
    },
    {
      title: "非空",
      dataIndex: "notnull",
      initialValue: false,
      render: (_, { notnull }) => {
        return <Switch checked={notnull} />;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Switch
            checked={record?.notnull}
            onChange={(checked) => {
              if (record) {
                record.notnull = checked;
              }
            }}
          />
        );
      },
    },
    {
      title: popTitle("唯一", "给字段创建唯一索引"),
      dataIndex: "unique",
      initialValue: false,
      render: (_, { unique }) => {
        return <Switch checked={unique} />;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Switch
            checked={record?.unique}
            onChange={(checked) => {
              if (record) {
                record.unique = checked;
              }
            }}
          />
        );
      },
    },
    {
      title: popTitle(
        "默认值",
        "用于生成初始化语句，需要引号包裹，如： 'yohann'"
      ),
      dataIndex: "initial",
    },
    {
      title: popTitle("字段备注", "用于生成初始化语句"),
      dataIndex: "comment",
    },
    {
      title: popTitle(
        "展示",
        "在管理页的表格展示，新增/修改表单用表单类型控制展示"
      ),
      dataIndex: "display",
      initialValue: false,
      render: (_, { display }) => {
        return <Switch checked={display} />;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Switch
            checked={record?.display}
            onChange={(checked) => {
              if (record) {
                record.display = checked;
              }
            }}
          />
        );
      },
    },
    {
      title: popTitle("展示名称", "在管理页的表格展示的表头名称"),
      dataIndex: "displayName",
    },
    {
      title: popTitle("表单类型", "用于新增/修改时表单的类型"),
      dataIndex: "formType",
      initialValue: "Hidden",
      render: (_, { formType }) => {
        return formTypes.find((item) => item.value === formType)?.label;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Select
            value={record?.formType}
            onChange={(value) => {
              if (record) {
                record.formType = value;
              }
            }}
            options={formTypes}
          />
        );
      },
    },
    {
      title: popTitle("查询类型", "用于控制查询条件输入"),
      dataIndex: "queryType",
      initialValue: "None",
      render: (_, { queryType }) => {
        return queryTypes.find((item) => item.value === queryType)?.label;
      },
      renderFormItem: (_, { record }) => {
        return (
          <Select
            value={record?.queryType}
            onChange={(value) => {
              if (record) {
                record.queryType = value;
              }
            }}
            options={queryTypes}
          />
        );
      },
    },
    {
      title: popTitle(
        "引用",
        "表单为引用时可以引用其他表，默认引用对方的主键，需要选择展示的字段"
      ),
      dataIndex: "reference",
      render: (_, { reference }) => {
        return reference?.join(".");
      },
      renderFormItem: (_, { record }) => {
        return (
          <Cascader
            options={options}
            value={record?.reference}
            displayRender={(values) => values.join(".")}
            onChange={(value) => {
              if (record) {
                record.reference = value as string[];
              }
            }}
          />
        );
      },
    },
    {
      title: "操作",
      valueType: "option",
    },
  ];

  return (
    <ProForm
      disabled={disabled}
      form={form}
      layout="horizontal"
      submitter={false}>
      <Card
        title="项目设定"
        extra={
          <Space>
            <Button type="primary" onClick={onFinish} hidden={disabled}>
              提交
            </Button>
            <Button
              type="primary"
              onClick={() => setOpen(!!checkAndGetForm())}
              disabled={false}>
              预览
            </Button>
            <Divider type="vertical" />
            <Button
              onClick={onClone}
              hidden={(!project?.shared && !getUserToken()?.admin) || !id}
              disabled={false}>
              克隆
            </Button>
            <Button onClick={() => setTour(true)} hidden={disabled || !!id}>
              引导
            </Button>
            <Button
              danger
              disabled={false}
              onClick={() => {
                if (location.pathname.includes("fusion")) {
                  navigate("/");
                } else {
                  navigate("/admin");
                }
              }}>
              返回
            </Button>
          </Space>
        }>
        <Space direction="vertical">
          <Space wrap ref={stackRef}>
            <ProForm.Item name={["project", "stackId"]} required>
              <CheckCard.Group
                disabled={disabled}
                options={stacks.map((stack) => ({
                  title: costPopover(stack.name, stack),
                  description: costPopover(
                    StackDescriptions(stack.description),
                    stack
                  ),
                  value: stack.id,
                }))}
              />
            </ProForm.Item>
          </Space>
          <Space direction="horizontal" ref={projectRef}>
            <ProForm.Item name={["project", "id"]} hidden />
            <ProFormText name={["project", "name"]} label="项目名称" required />
            <ProFormText
              name={["project", "basePackage"]}
              label={popTitle("基础包名", "Java基础包名，如com.yohann.fusion")}
              required
            />
            <ProFormText
              name={["project", "author"]}
              label={popTitle("作者签名", "用于生成的项目文件的创建人")}
              required
            />
            <ProFormText name={["project", "userId"]} hidden />
            <ProFormText name={["project", "cloneCount"]} hidden />
            <ProFormText name={["project", "updateUserId"]} hidden />
            <ProFormText name={["project", "createDate"]} hidden />
            <ProFormText name={["project", "updateDate"]} hidden />
            <ProForm.Item name={["database", "id"]} hidden />
            <ProForm.Item name={["database", "projectId"]} hidden />
            <ProFormText
              name={["database", "name"]}
              label={popTitle(
                "数据库名",
                "项目链接的数据库名称，用于生成初始化语句，采用小写下划线命名"
              )}
              required
            />
            <ProFormText
              name={["database", "charset"]}
              label="Charset"
              initialValue={"UTF8"}
              hidden
            />
            <ProFormSwitch
              name={["project", "shared"]}
              initialValue={false}
              label="共享"
              hidden={!userToken?.admin}
            />
            <ProFormSwitch
              name={["project", "sign"]}
              label={popTitle("去广", "去除文件开头出现的推广信息")}
              initialValue={false}
            />
            <ProFormSwitch
              name={["project", "remark"]}
              label={popTitle("注释", "添加技术栈对应的代码注释")}
              initialValue={false}
            />
          </Space>
        </Space>
      </Card>
      <ProFormDependency name={["tables", editableKey, options]}>
        {() => (
          <ProFormList
            name="tables"
            initialValue={[{}]}
            creatorButtonProps={{
              creatorButtonText: "添加数据表",
              loading: false,
            }}
            deleteIconProps={disabled ? false : undefined}
            copyIconProps={false}
            itemRender={({ listDom, action }, { record }) => (
              <ProCard
                bordered
                title={"表设计 for " + record?.name}
                extra={action}>
                {listDom}
              </ProCard>
            )}>
            <Space wrap ref={tableRef}>
              <ProFormText name="id" hidden={true} />
              <ProFormText name="databaseId" hidden={true} />
              <ProFormText
                name="name"
                label={popTitle("表名", "采用小写下划线命名")}
                required
              />
              <ProFormText
                name="engine"
                label="Engine"
                hidden
                initialValue={"InnoDB"}
              />
              <ProFormText
                name="charset"
                label="Charset"
                hidden
                initialValue={"UTF8"}
              />
              <ProFormText
                name="comment"
                label={popTitle("表备注", "用于生成代码注释和初始化语句")}
                required
              />
              <ProFormSwitch
                name="display"
                label={popTitle(
                  "是否展示",
                  "在菜页面单中出现该表管理，如果需要则填写展示名"
                )}
                initialValue={true}
              />
              <ProFormText name="displayName" label="展示名称" />
              <ProFormSelect
                name="actions"
                label={popTitle(
                  "支持操作",
                  "管理界面出现的功能，没有选择则不会出现"
                )}
                mode="multiple"
                showSearch={false}
                initialValue={["Search", "Add", "Update", "Delete"]}
                options={actionTypes}
              />
            </Space>
            <Space ref={columRef}>
              <ProForm.Item name="columns" trigger="onValuesChange">
                <EditableProTable<Column>
                  rowKey="id"
                  columns={columns}
                  onRow={(record) => ({
                    onClick: () => {
                      const id = record.id?.toString();
                      if (id) {
                        setEditableKey(id);
                      }
                      refreshOption();
                    },
                  })}
                  recordCreatorProps={{
                    newRecordType: "dataSource",
                    creatorButtonText: "添加字段",
                    position: "bottom",
                    record: () => {
                      return {
                        id: Math.random(),
                        primary: false,
                        auto: false,
                        notnull: true,
                        unique: false,
                        display: true,
                        type: "INT",
                        queryType: "none",
                        formType: "Hidden",
                      } as Column;
                    },
                  }}
                  editable={{
                    editableKeys: [editableKey],
                    type: "multiple",
                    deleteText: "删除",
                    actionRender: (row, _, dom) => {
                      return [dom.delete];
                    },
                  }}
                />
              </ProForm.Item>
            </Space>
          </ProFormList>
        )}
      </ProFormDependency>
      <style>
        {`
            ::-webkit-scrollbar {
                display: none;
            }
        `}
      </style>

      <SourceCodePreview
        open={open}
        onClosed={() => setOpen(false)}
        context={form.getFieldsValue()}
      />
      <Tour open={tour} onClose={() => setTour(false)} steps={steps} />
    </ProForm>
  );
};

export default DefinitionContextEdit;
