import { Space, Tag } from "antd";

const colors = [
  "#1677FF",
  "#00B96B",
  "#5A54F9",
  "#9E339F",
  "#F2BD27",
  "#ED4192",
  "#E0282E",
  "#F4801A",
];
const StackDescriptions = (description: string) => {
  const content = description.split(",").map((value, index) => {
    const i = (index + description.length) % 8;
    const color = colors[i];
    return <Tag color={color}>{value}</Tag>;
  });

  return <Space wrap>{content}</Space>;
};

export default StackDescriptions;
