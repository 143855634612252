import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router";
import { login } from "../api/FusionProjectAPI";
import { errorHandler } from "../utils/RequestUtils";

const LoginForm = () => {
  const navigate = useNavigate();
  const onFinish = (user: any) => {
    login(user.username, user.password)
      .then(() => {
        navigate("/");
      })
      .catch(errorHandler);
  };

  return (
    <Form onFinish={onFinish} layout="horizontal" size="large">
      <Form.Item
        label="账号"
        name="username"
        rules={[{ required: true, message: "请输入账号" }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="密码"
        name="password"
        rules={[{ required: true, message: "请输入密码" }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
